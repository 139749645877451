<template>
  <b-modal 
    id="create-kit-modal" 
    :title="'Montar kit'"
    size="xl"
    @hide="onClose"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="2">
          <label>
            Código do kit
          </label>
          <div class="print-container">
            <b-form-input
              id="kit-code"
              type="text"
              v-model="form.code"
              placeholder="00000000"
              readonly
            />
            <Print v-show="form.code" class="printer" @click="printBarcode"/>
          </div>
        </b-col>
        <b-col cols="5">
          <label>
            Selecionar
          </label>
          <Autocomplete
            v-model="form.kit_template_id"
            :options="templatesOptions"
            placeholder="Selecionar template"
            @input="inputTemplate($event)"
            @update="searchTemplate($event)"
            :disabled="!!form.id"
            debounce="1000"  
          />
        </b-col>
        <b-col cols="5">
          <label>
            Origem
          </label>
          <Autocomplete
            v-model="form.kit_origin_id"
            :options="originOptions"
            :onAddOption="createOrigin"
            placeholder="Selecionar origem"
            @input="inputOrigin($event)"
            @update="searchOrigin($event)"
            debounce="1000"  
          />
        </b-col>
      </b-row>
      <b-row v-show="Object.keys(form.kit_products).length">
        <b-col cols="12 mt-2">
          <label for="kit-barcode">
            Código de barras
            <span class="help">(opcional)</span>
          </label>
          <div class="barcode-input">
            <b-form-input
              id="kit-barcode"
              type="text"
              v-model="barcode"
              @update="searchBarcode($event)"
              placeholder="00001"
              :debounce="3000"
            />
            <b-spinner v-show="loadingBarcode" class="loading-barcode" variant="primary" small />
            <Barcode class="barcode" />
          </div>
        </b-col>
      </b-row>
      <hr />
    </b-container>
    <b-container class="product-container">
      <div v-show="loadingProducts" class="text-align-center pt-5">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <div v-for="(productId, index) in Object.keys(form.kit_products)" :key="index" v-show="!loadingProducts">
        <b-row>
          <b-col cols="1">
            <span class="text-align-center product-header">
              {{ 
                form.kit_products[productId][0]?.product?.pivot?.quantity ??
                form.kit_products[productId]?.length
              }}
            </span>
          </b-col>
          <b-col cols="11">
            <span class="product-header pl-3">
              {{ form.kit_products[productId][0]?.product?.barcode }}
              - {{ form.kit_products[productId][0]?.product?.name }}
            </span>
          </b-col>
        </b-row>
        <div class="product-div">
          <b-row cols="12" v-for="(product, i) in form.kit_products[productId]" :key="i" class="product-content">
            <b-col cols="1">
              <b-form-input
                id="kit-product-quantity"
                class="text-align-center"
                :class="{'error': form.kit_products[productId][i].quantity < 1}"
                type="number"
                v-model="form.kit_products[productId][i].quantity"
                min="1"
              />
            </b-col>
            <b-col cols="4">
              <div>
                <Autocomplete
                  v-model="form.kit_products[productId][i].batch_id"
                  :options="batchesOptions?.filter(batch => batch.productId === productId)"
                  :emptyResultsMessage="loadingBatches ? 'Buscando lotes...' : 'Nenhum lote encontrado'"
                  placeholder="Selecionar lote"
                  @input="inputBatch(productId, i, $event)"
                  @focus="getBatches(productId)"
                  debounce="1000"  
                />
              </div>
            </b-col>
            <b-col cols="2">
              <date-picker
                class="full payment-date"
                v-model="form.kit_products[productId][i].expiration_date"
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA"
                :clearable="false"
                :lang="langDatePicker"
              />
            </b-col>
            <b-col cols="2">
              <b-form-input 
                id="kit-product-cost" 
                type="number" 
                placeholder="Custo"
                min="0"
                :class="{'is-invalid': form.kit_products[productId][i].cost < 0}"
                v-model="form.kit_products[productId][i].cost"
              />
            </b-col>
            <b-col cols="2">
              <b-form-input 
                id="kit-product-selling-cost" 
                type="number" 
                min="0"
                :class="{'is-invalid': form.kit_products[productId][i].selling_cost < 0}"
                placeholder="Valor de venda"
                v-model="form.kit_products[productId][i].selling_cost"
              />
            </b-col>
            <b-col cols="1" class="trash-container">
              <Trash class="icon trash" @click="clearProduct(productId, i)"/>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <div slot="modal-footer" class="w-100">
      <b-btn 
        class="float-right" 
        variant="primary" 
        :disabled="!isValidForm()"
        @click="handleSave"
      >
        {{ form?.id ? 'Atualizar' : 'Salvar' }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { saveAs } from 'file-saver'

export default {
  name: 'kit-modal',
  components: {
    Trash: () => import('@/assets/icons/trash.svg'), 
    Barcode: () => import('@/assets/icons/barcode.svg'),
    Autocomplete: () => import('../../Autocomplete.vue'),
    Print: () => import('@/assets/icons/printer.svg'),
  },
  props: {
    kit: Object || null,
  },
  data () {
    return {
      form: {
        id: null,
        code: this.getRandomCode(),
        kit_template_id: null,
        kit_origin_id: null,
        kit_products: {},
      },
      clinic: JSON.parse(localStorage.getItem('clinic')),
      templatesOptions: [],
      originOptions: [],
      batchesOptions: [],
      barcode: null,
      loadingBatches: false,
      loadingBarcode: false,
      loadingProducts: false,

      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      }
    }
  },
  methods: {
    getRandomCode () {
      return Math.floor(Math.random() * 1000000000000)
    },
    onClose () {
      this.resetForm()
      this.$emit('close')
    },
    resetForm () {
      this.form = this.getDefaultForm()
      this.templatesOptions = [] 
    },
    getDefaultForm () {
      return {
        id: null,
        code: this.getRandomCode(),
        kit_template_id: null,
        kit_origin_id: null,
        kit_products: {},
      }
    },
    isValidForm () {
      const validProducts = (
        Object.keys(this.form.kit_products).length
        && Object.keys(this.form.kit_products).every(productId => {
          return this.form.kit_products[productId].length
          && this.form.kit_products[productId].every(product => {
            return product.batch_id
            && product.quantity > 0
            && product.expiration_date
            && product.cost >= 0
            && product.selling_cost >= 0
          })
        })
      )

      return (
        this.form?.code 
        && this.form?.kit_template_id
        && this.form?.kit_origin_id
        && validProducts
      )
    },
    inputOrigin (originId) {
      this.$set(this.form, 'kit_origin_id', originId)
    },
    async createOrigin (origin) {
      try {
        const res = await this.api.createKitOrigin({ name: origin, clinic_id: this.clinic.id })
        this.originOptions.push({
          value: res.data.id,
          label: res.data.name,
        })
        this.$set(this.form, 'kit_origin_id', res.data.id)
      } catch (error) {
        this.$toast.error('Não foi possível criar a origem, contate o suporte se o erro persistir.')
      }
    },
    async searchOrigin (query) {
      if (!query?.length) return;

      try {
        const res = await this.api.getKitOrigins(this.clinic.id, {name:query}) 
        const newOptions = res.data.map(origin => ({
          value: origin?.id,
          label: origin?.name,
        }))

        this.originOptions = this.originOptions.concat(newOptions)
        this.originOptions = this.originOptions.filter((option, index, self) => {
          return self.findIndex(opt => opt.value === option.value) === index
        })

      } catch (error) {
        this.$toast.error('Não foi possível encontrar a origem, contato o suporte se o erro persistir.')
      }
    },
    async searchTemplate (query) {
      if (!query?.length) return;

      try {
        const res = await this.api.getKitTemplates(this.clinic.id, {query:query}, false) 
        const newOptions = res.data.map(template => ({
          value: template?.id,
          label: template?.name,
          code: template?.code,
          products: template?.products,
        }))

        this.templatesOptions = this.templatesOptions.concat(newOptions)
        this.templatesOptions = this.templatesOptions.filter((option, index, self) => {
          return self.findIndex(opt => opt.value === option.value) === index
        })

      } catch (error) {
        this.$toast.error('Não foi possível encontrar o template, contato o suporte se o erro persistir.')
      }
    },
    clearTemplate () {
      this.$set(this.form, 'kit_template_id', null)
      this.$set(this.form, 'kit_products', {})
    },
    inputTemplate (templateId) {
      if (this.form.id) return
      if (!templateId) return this.clearTemplate()

      const template = this.templatesOptions.find(template => template.value === templateId)

      const kitProducts = {}
      this.$set(this.form, 'kit_template_id', templateId)
      template.products.map(product => {
        if (!this.form.kit_products[product.id]) {
          this.$set(kitProducts, product.id, [])
        }
        for (let i = 0; i < product.pivot.quantity; i++) {
          kitProducts[product.id].push({
            id: null,
            kit_id: this.form?.id,
            product_id: product.id,
            batch_id: null,
            quantity: 1,
            expiration_date: null,
            cost: null,
            selling_cost: null,
            product: product,
          })
        }
      })
      this.$set(this.form, 'kit_products', kitProducts)
    },
    async handleSave () {
      const products = []
      Object.keys(this.form.kit_products).map(productId => {
        this.form.kit_products[productId].map(product => {
          products.push(product)
        })
      })

      this.$set(this.form, 'products', products)

      if (this.form?.id) 
        await this.updateKit()
      else
        await this.saveKit()
    },
    async saveKit () {
      if (!this.isValidForm()) return;

      this.form.clinic_id = this.clinic.id
      const loading = this.$loading.show()
      try {
        await this.api.createKit(this.form)
        this.$toast.success('Kit salvo com sucesso!')
        this.$emit('save')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.$bvModal.hide('create-kit-modal')
        loading.hide()
        this.resetForm()
      }
    },
    async updateKit () {
      if (!this.isValidForm()) return;

      const loading = this.$loading.show()
      try {
        await this.api.updateKit(this.form.id, this.form)
        this.$toast.success('Kit atualizado com sucesso!')
        this.$emit('save')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.$bvModal.hide('create-kit-modal')
        loading.hide()
        this.resetForm()
      }
    },
    chevronRotate() {
      if(!this.chevronState) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = true
      }
    },
    resetChevronState() {
      if(this.chevronState) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = false
      }
    },
    clearProduct (productId, index) {
      const product = {
        id: null,
        kit_id: this.form?.id,
        batch_id: null,
        product_id: this.form.kit_products[productId][index]?.product?.id,
        quantity: 1,
        expiration_date: null,
        cost: null,
        selling_cost: null,
        product: this.form.kit_products[productId][index]?.product,
      }
      this.$set(this.form.kit_products[productId], index, product)
    },
    inputBatch (productId, index, batchId) {
      this.form.kit_products[productId][index].batch_id = batchId
    },
    async getBatches (productId) {
      try {
        this.loadingBatches = true
        const res = await this.api.getProductBatches(productId)

        const newOptions = res.data.map(batch => ({
          value: batch?.id,
          label: batch?.batch,
          productId: productId,
        }))

        this.batchesOptions = this.batchesOptions.concat(newOptions)
        this.batchesOptions = this.batchesOptions.filter((option, index, self) => {
          return self.findIndex(opt => opt.value === option.value) === index
        })
      } catch (error) {
        this.$toast.error('Não foi possível buscar os lotes, contate o suporte se o erro persistir.')
      } finally {
        this.loadingBatches = false
      }
    },
    addKitTemplateOption (kit) {
      if (kit.kit_template_id) {
        this.templatesOptions.push({
          value: kit.kit_template_id,
          label: kit.template?.name,
          code: kit.template?.code,
          products: kit.template?.products,
        })
      }
    },
    addOriginOption (kit) {
      if (kit.kit_origin_id) {
        this.originOptions.push({
          value: kit.kit_origin_id,
          label: kit.origin?.name
        })
      }
    },
    setFormExpirationDateToMoment () {
      Object.keys(this.form.kit_products).forEach(key => {
        this.form.kit_products[key].forEach(product => {
          product.expiration_date = this.moment(product.expiration_date).toDate()
        })
      })
    },
    async setBatchesOptions (kit) {
      Object.keys(kit.kit_products).forEach(async key => {
        this.loadingProducts = true
        await this.getBatches(key)
        this.loadingProducts = false
      })
    },
    async searchBarcode (barcode) {
      if (!barcode) return

      this.loadingBarcode = true
      try {
        const res = await this.api.getWarehouseEntryTransactions(this.clinic.id, 1, null, null, null, null, null, barcode)
        const product = res.data.data[0]

        if (!product) {
          this.$toast.error('Produto não encontrado')
          return
        }

        if (!this.form.kit_products[product?.product_id]) {
          this.$toast.error('Produto não pertence a este kit')
          return
        }

        await this.getBatches(product?.product_id)

        const kitProduct = this.form.kit_products[product?.product_id].find(el => 
          !el.batch_id && !el.cost && !el.selling_cost && !el.expiration_date && !el.cost
        )

        if (kitProduct) {
          this.$set(kitProduct, 'batch_id', product?.batch_balance?.id)
          this.$set(kitProduct, 'quantity', product?.quantity)
          this.$set(kitProduct, 'cost', product?.purchase_price)
          this.$set(kitProduct, 'selling_cost', product?.sale_price)
          this.$set(kitProduct, 'expiration_date', this.moment(product?.expiration_date).toDate())

          this.$toast.success('Produto adicionado com sucesso!')
          this.barcode = null
        } else {
          this.$toast.error('Todos produtos desta categoria já foram adicionados!')
        }
      } catch (error) {
        this.$toast.error('Não foi possível buscar o produto, contate o suporte se o erro persistir.')
      } finally {
        this.loadingBarcode = false
      }
    },
    async printBarcode () {
      const isLoading = this.$loading.show()
      try {
        const {data}  = await this.api.printBarcode(this.form.code)

        const pdfData = Uint8Array.from(window.atob(data), c =>
          c.charCodeAt(0)
        ).buffer

        const blob = new Blob([pdfData], { type: 'application/pdf' })
        saveAs(blob, 'código de barras')
      } catch (error) {
        this.$toast.error('Não foi possível imprimir o código de barras, contate o suporte se o erro persistir.')
      } finally {
        isLoading.hide()
      }
   
    }
  },

  watch: {
    kit: {
      async handler (kit) {
        if (kit) {
          this.addOriginOption(kit)
          this.addKitTemplateOption(kit)

          await this.setBatchesOptions(kit)

          this.form = JSON.parse(JSON.stringify(kit))
          this.setFormExpirationDateToMoment()

        } else {
          this.resetForm()
        }
      },
      deep: true
    },
  }
}
</script>

<style lang="scss">
#create-kit-modal {
  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .trash {
    stroke: var(--states-red-soft);
  }

  label {
    color: var(--dark-blue);
    font-weight: 600;

    .help {
      font-size: 0.8rem;
      color: var(--type-active);
      font-weight: 400;
    }
  }

  .print-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      position: relative;
    }

    input:read-only {
      background-color: var(--states-gray-soft);
    }

    .printer {
      position: absolute;
      margin-left: 75%;
    }
  }

  .barcode-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      position: relative;
      padding-left: 30px;
    }

    .loading-barcode {
      position: absolute;
      margin-left: 10px;
    }

    .barcode {
      position: absolute;
      margin-left: 94%;
      transform: scale(0.8);
    }
  }

  .product-container {
    max-height: 35vh;
    overflow-y: auto;
    padding-bottom: 100px;

    .product-header {
      display: block;
      width: 100%;
      color: var(--type-active);
      font-weight: 600;
      padding: 5px;
      border-radius: 8px;
      background: var(--neutral-200);
      border: 1px solid var(--neutral-300);
    }

    .product-div {
      margin: 10px 0;
      border-radius: 8px;
      background: var(--neutral-100);
    }

    .product-content {
      width: 100%;
      padding: 10px;
      margin: 0px 0;
      border-radius: 8px;

      .vx-input {
        color: var(--type-active);
        font-weight: 500;
        border: 1px solid var(--neutral-300) !important;
      }

      .vx-datepicker-popup {
        margin-top: 10px;
        left: 585px !important;
      }

      .trash-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: middle;
        align-self: center;
        
        .trash {
          stroke: var(--states-red-soft);
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: var(--neutral-000);
    border-radius: 0 0 8px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--neutral-300);
    border-radius: 100px;
    border: 3px solid var(--neutral-000);
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: var(--neutral-200);
  }
}
</style>